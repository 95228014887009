import { ChangeEvent, FormEvent, useState } from "react";

import { CheckboxProps, DropdownProps, InputOnChangeData, Message, Segment, Select } from "semantic-ui-react";

import SettingsSlidersVector from "../../../assets/SettingsSlidersVector";
import { SmartTargetFilter } from "../../../types/types";
import Button from "../../UI/Button/Button";
import Checkbox from "../../UI/Checkbox/Checkbox";

import "./SmartTargetFilterItem.scss";
import Input from "../../UI/Input/Input";

export interface SmartTargetFilterItemProps {
	filterState: SmartTargetFilter;
	onToggle: (isChecked: boolean) => void;
	onSettingsChange?: (comparison: string | null, value: string | null) => void;
}

export const smartTargetComparisons = ["", "<", "<=", "=", ">=", ">"] as const;
export type SmartTargetComparison = (typeof smartTargetComparisons)[number];

export default function SmartTargetFilterItem({
	filterState,
	onToggle,
	onSettingsChange,
}: SmartTargetFilterItemProps) {
	const [isSettingsOpen, setSettingsOpen] = useState<boolean>(false);
	const [error, setError] = useState("");

	const improvement = filterState.customImprovement;

	const onToggleChange = (_event: FormEvent, data: CheckboxProps) => {
		const checkStatus = data.checked ?? false;
		onToggle(checkStatus);
	};

	const onComparisonChange = (_event: FormEvent, data: DropdownProps) => {
		const comparison = data.value as SmartTargetComparison;
		onSettingsChange && onSettingsChange(comparison, filterState.value ? String(filterState.value) : "");
	};

	const onValueChange = (_event: ChangeEvent, data: InputOnChangeData) => {
		const inputValue = data.value;
		setError("");
		if (inputValue !== "") {
			const inputNum = Number(inputValue);
			if (isNaN(inputNum)) {
				// the user shouldn't be able to enter non-numeric, but double check for fun
				setError("Value must be a number");
			}

			if (improvement.min && inputNum < improvement.min) {
				setError(`Value must be between ${improvement.min} and ${improvement.max}`);
			} else if (improvement.max && inputNum > improvement.max) {
				setError(`Value must be between ${improvement.min} and ${improvement.max}`);
			}
		}

		onSettingsChange && onSettingsChange(filterState.operator ?? "", inputValue);
	};

	return (
		<div className={"filter-item-container " + (isSettingsOpen ? "filter-item-expanded" : "filter-item-collapsed")}>
			<Segment
				className={"filter-item filter-item-upper " + (!filterState.hidden ?? true ? "filter-item-selected" : "")}
			>
				<Checkbox onChange={onToggleChange} checked={!filterState.hidden ?? true} />
				{improvement.name}
				{improvement.accepts_parameters && (
					<Button className={"float-right"} onClick={() => setSettingsOpen(!isSettingsOpen)}>
						<SettingsSlidersVector />
					</Button>
				)}
			</Segment>
			{improvement.accepts_parameters && (
				<>
					<Segment className={"filter-item-lower"}>
						{improvement.name.split('(')[0].trim()} is
						<Select
							options={smartTargetComparisons.map((comparison: SmartTargetComparison) => ({
								key: comparison,
								text: comparison,
								value: comparison,
							}))}
							onChange={onComparisonChange}
							inline
							value={filterState.operator ?? ""}
						/>
						<span className={"filter-item-connective"}>
							{filterState.operator === "<" || filterState.operator === ">" ? "than " : "to "}
						</span>
						<Input
							placeholder={"value"}
							value={filterState.value}
							onChange={onValueChange}
							type="number"
						/>
						{improvement.units}
					</Segment>
					{error && <Message error>{error}</Message>}
				</>
			)}
		</div>
	);
}
