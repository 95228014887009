import { useEffect, useState } from "react";
import { Message, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from "semantic-ui-react";
import { useRestClientContext } from "../../../contexts/RestClientContext";
import Property from "../../../rest/Property";

import {
	SapImprovementNiceNames,
	sapImprovementUnitsMap,
	SmartTarget,
	SmartTargetFields,
	SmartTargetParams,
	SmartTargetStatus,
} from "../../../types/types";
import SmartTargetResultModal from "../../Modal/SmartTargetResultModal";
import Preloader from "../../UI/Preloader/Preloader";
import GenerateSmartTarget from "./GenerateSmartTarget";
import SmartTargetStatusChip from "./SmartTargetStatusChip";
import SmartTargetTableDropdown from "./SmartTargetTableDropdown";

import "./SmartTargetsPanel.scss";

interface SmartTargetsPanelProps {
	property: Property;
}

const formatResultValue = (target: SmartTargetFields, value: number) => {
	let formattedValue;
	if (sapImprovementUnitsMap[target].unit === "£") {
		formattedValue = `£${value}`;
	} else if (!!sapImprovementUnitsMap[target].unit) {
		formattedValue = `${value} ${sapImprovementUnitsMap[target].unit}`;
	} else {
		formattedValue = "" + value;
	}

	if (sapImprovementUnitsMap[target].timeFrame) {
		formattedValue += ` /${sapImprovementUnitsMap[target].timeFrame}`;
	}
	return formattedValue;
};

export default function SmartTargetsPanel({ property }: SmartTargetsPanelProps) {
	const client = useRestClientContext();
	const [smartTargets, setSmartTargets] = useState<SmartTarget[]>([]);
	const [numSubmissions, setNumSubmissions] = useState(0);
	const [selectedSmartTarget, setSelectedSmartTarget] = useState<SmartTarget | null>(null);
	const [isResultsModalOpen, setIsResultsModalOpen] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	/** Map of each field and their current values. Also, whether the target value should be above the current or not. **/
	const currentValues: Record<SmartTargetFields, { current?: number; validateAbove: boolean }> = {
		co2_saving: { current: property.data.sap_data.current_co2_use, validateAbove: false },
		co2_value: { current: property.data.sap_data.current_co2_use, validateAbove: true },
		cost_saving: { current: property.data.sap_data.total_cost_current, validateAbove: false },
		energy_saving: { current: property.data.sap_data.current_energy_use, validateAbove: false },
		heat_demand: { current: property.data.sap_data.current_heat_demand, validateAbove: true },
		hlp: { current: property.data.sap_data.as_built_hlp, validateAbove: true },
		sap_value: { current: property.data.epc_rating, validateAbove: true },
	};

	useEffect(() => {
		setLoading(true);
		client
			.fetchAllPropertySmartTargets(property.teamId, property.id, ["improvements"])
			.then((response: Response) => response.json())
			.then((json: any) => {
				setSmartTargets(json);
				setLoading(false);
			});
	}, [client, property, numSubmissions]);

	/**
	 * Get the full smart target data for the selected target - this is then stored in state so that we don't need to
	 * fetch again if the modal is reopened.
	 * @param smartTarget
	 * @return Promise<SmartTarget>
	 */
	const updateSmartTarget = (smartTarget: SmartTarget): Promise<SmartTarget> => {
		return new Promise<SmartTarget>((resolve, reject) => {
			client
				.fetchPropertySmartTargets(property.teamId, property.id, smartTarget.id)
				.then((response: Response) => response.json())
				.then((json: SmartTarget) => {
					setSelectedSmartTarget(json);
					resolve(json);

					const newSmartTargets = smartTargets.map((iterSmartTarget) => {
						if (iterSmartTarget.id === smartTarget.id) {
							return json;
						}
						return iterSmartTarget;
					});

					setSmartTargets(newSmartTargets);
				})
				.catch(() => reject("Could not update smart target data"));
		});
	};

	const formatResultsList = (smartTarget: SmartTarget) => {
		if (smartTarget.status === SmartTargetStatus.RUNNING || smartTarget.status === SmartTargetStatus.PENDING) {
			return <span className="result-light">In Progress...</span>;
		}
		if (
			smartTarget.status === SmartTargetStatus.ERROR ||
			!smartTarget.custom_improvement_sets ||
			!smartTarget.custom_improvement_sets.length
		) {
			return <span className="result-light">No Results Available</span>;
		}

		const namesList = smartTarget.custom_improvement_sets.map((improvementSet) => improvementSet.name);
		if (namesList.length > 2) {
			const remainder = namesList.length - 2;
			return (
				<>
					<b>{namesList.slice(0, 2).join(", ")}</b>
					<span className="result-light">
						+ {remainder} {remainder === 1 ? "Other" : "Others"}
					</span>
				</>
			);
		}
		return <b>{namesList.join(", ")}</b>;
	};

	const onViewSmartTarget = (smartTarget: SmartTarget) => {
		setSelectedSmartTarget(smartTarget);
		setIsResultsModalOpen(true);
	};

	const cancelPendingSmartTarget = (smartTarget: SmartTarget) => {
		client.cancelPendingSmartTargets(property.teamId, property, smartTarget).then(() => {
			const newSmartTargets = smartTargets.map((targetItem: SmartTarget) =>
				targetItem.id === smartTarget.id ? { ...targetItem, status: SmartTargetStatus.CANCELLED } : targetItem
			);
			setSmartTargets(newSmartTargets);
		});
	};

	const onDelete = (smartTarget: SmartTarget) => {
		client.deleteSmartTargets(property.teamId, property, smartTarget).then(() => {
			const newSmartTargets = smartTargets.filter((targetItem: SmartTarget) => targetItem.id !== smartTarget.id);
			setSmartTargets(newSmartTargets);
		});
	};

	const onSubmit = (params: SmartTargetParams) => {
		setLoading(true);
		client.createSmartTargets(property.teamId, property, params).then(() => setNumSubmissions(numSubmissions + 1)); // NB: Trigger reload
	};

	return (
		<>
			{loading ? (
				<Preloader />
			) : !!smartTargets.length ? (
				<Table>
					<TableHeader>
						<TableRow>
							<TableHeaderCell>ID</TableHeaderCell>
							<TableHeaderCell>Target</TableHeaderCell>
							<TableHeaderCell>Current Value</TableHeaderCell>
							<TableHeaderCell>Target Value</TableHeaderCell>
							<TableHeaderCell>Status</TableHeaderCell>
							<TableHeaderCell>Results</TableHeaderCell>
							<TableHeaderCell />
						</TableRow>
					</TableHeader>
					<TableBody>
						{smartTargets.map((obj: SmartTarget) => (
							<TableRow key={obj.id}>
								<TableCell>{obj.id}</TableCell>
								<TableCell>{SapImprovementNiceNames[obj.target]}</TableCell>
								<TableCell>
									{!!currentValues[obj.target].current ? (
										formatResultValue(obj.target, currentValues[obj.target].current!)
									) : (
										<span className="result-light">N/A</span>
									)}
								</TableCell>
								<TableCell>{formatResultValue(obj.target, obj.value)}</TableCell>
								<TableCell>
									<SmartTargetStatusChip type={obj.status} />
								</TableCell>
								<TableCell>{formatResultsList(obj)}</TableCell>
								<TableCell className="actions-dropdown">
									<SmartTargetTableDropdown
										smartTargetStatus={obj.status}
										onView={() => onViewSmartTarget(obj)}
										onCancel={() => cancelPendingSmartTarget(obj)}
										onDelete={() => onDelete(obj)}
									/>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			) : (
				<Message info>
					<Message.Header>No previous smart targets found</Message.Header>
					<Message.Content>Create a smart target by filling out the form below</Message.Content>
				</Message>
			)}
			<GenerateSmartTarget onSubmit={onSubmit} isLoading={loading} currentValues={currentValues} />
			{isResultsModalOpen && selectedSmartTarget && (
				<SmartTargetResultModal
					smartTarget={selectedSmartTarget}
					currentImprovementSets={property.data.custom_improvement_sets}
					updateSmartTarget={updateSmartTarget}
					onClose={() => setIsResultsModalOpen(false)}
				/>
			)}
		</>
	);
}
